import {
  Button,
  Checkbox,
  Collapse,
  Modal,
  Select,
  Tooltip,
  Upload,
  message,
} from "antd";
import { ExpandMoreOutlined, UploadOutlined } from "@mui/icons-material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  SetProjectName,
  projectsAccessLevel,
  reloadProjects,
  saveProjectDetails,
  setUnsavedChanges,
  setUnsavedChangesModal,
  clearNewRows as clearRowsData,
  clearDeletedRows,
  clearAllRows,
} from "redux/project-reducer";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Space } from "antd";
import { ActiveUsersService } from "apis/Services/ActiveUsersService";
import ArrowDownIcon from "assets/images/arrow-down.svg";
import ButtonComponent from "components/button-component";
import CalendarIcon from "assets/images/calendar-icon.svg";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Container from "components/container";
import ContentContainer from "components/content-container";
import DatePicker from "components/project-details-components/DatePicker";
import Dragger from "antd/es/upload/Dragger";
import FilterIcon from "assets/images/filter-icon.svg";
import FilterModal from "components/project-details-components/FilterModal";
import GrowingAlert from "components/growing-alert";
import Icon from "@ant-design/icons/lib/components/Icon";
import InputComponent from "components/input-component";
import LoadingComponent from "components/loading-component";
import PersonIcon from "assets/images/person-icon.svg";
import PersonsModal from "components/project-details-components/PersonsModal";
import ProjectDivision from "components/project-division";
import ProjectHeader from "components/project-header";
import { ProjectServices } from "apis/Services/ProjectService";
import SearchIcon from "assets/images/search-icon.svg";
import SortIcon from "assets/images/sort-icon.svg";
import Text from "components/text";
import { TypesArr } from "mockups/PROJECT_FILTERS";
import moment from "moment";
import styles from "./details.module.css";
import { toast } from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import ExportTable from "components/export-table";
import Settings from "components/settings-modal";
import { Tabs, Dropdown, Affix } from "antd";
import {
  DownOutlined,
  FilePdfOutlined,
  HistoryOutlined,
  SettingOutlined,
  FormOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import PostSubmittals from "components/post-submittals";
import BulkDelete from "components/bulk-delete";
import BulkAssignEdit from "components/bulk-assign-edit";
import { HiddenItemsService } from "apis/Services/HiddenItemsService";

const FILTERDATA = [
  // {
  //   text: "Filter",
  //   image: {
  //     src: FilterIcon,
  //     alt: "filter-icon",
  //   },
  //   onClickAction: "handleFilterModalVisibility",
  //   slug: "filter",
  //   showModal: ({ isVisible, setIsVisible, handleFilterFunction }) => (
  //     <FilterModal
  //       isVisible={isVisible}
  //       closeModal={setIsVisible}
  //       handleFilterFunction={handleFilterFunction}
  //     />
  //   ),
  // },
  {
    text: moment().format("MMM DD"),
    image: {
      src: CalendarIcon,
      alt: "calendar-icon",
    },
    onClickAction: "handleDatePickerVisibility",
    slug: "date",
    showModal: ({ isVisible, setIsVisible }) => (
      <DatePicker isVisible={isVisible} closeModal={setIsVisible} />
    ),
  },
];

const ProjectDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { company_id, id } = useParams();
  // const [projectDetails, setprojectDetails] = useState({});
  const [isLoading, setisLoading] = useState(true);
  const [selectedView, setSelectedView] = useState();
  const {
    projectDetails,
    reload,
    projectsAccessLevelValue,
    unsavedChangesModal,
    unsavedChanges,
  } = useSelector((state) => state.projectDetails);
  // const [ActiveUsers, setActiveUsers] = useState([]);

  // Get projects details from the backend
  useEffect(() => {
    // console.log(projectsAccessLevelValue, "projectsAccessLevelValue");
    setisLoading(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = { company: company_id, project: id, code, loginEmail: email };
    ProjectServices.getProjectsDetails(ReqObj)
      .then((res) => {
        dispatch(saveProjectDetails(res));
        // dispatch(SetProjectName(res?.projectInfo || ""));
        const accessLevel = res?.usersInfo[0]?.accessLevel;
        setSelectedView(res?.viewsInfo?.lastUsedView);
        // localStorage.setItem("accessLevel", accessLevel);
        dispatch(projectsAccessLevel(accessLevel));
      })
      .catch((err) => {
        console.log(err);
        dispatch(projectsAccessLevel(err.accessLevel));
      })
      .finally(() => setisLoading(false));
  }, [reload]);

  // get the access level value from local storage
  useEffect(() => {
    dispatch(clearRowsData());
    const accessLevel = localStorage.getItem("accessLevel");
    dispatch(projectsAccessLevel(accessLevel));
  }, []);

  const [divisionsData, setDivisionsData] = useState(TypesArr);

  const handleFilter = (slugArr) => {
    const filteredSlug = [];
    if (!slugArr.length) {
      setDivisionsData(TypesArr);
    } else {
      slugArr.forEach((ele) => {
        TypesArr.filter((e) => {
          if (e.slug == ele) filteredSlug.push(e);
        });
      });
      setDivisionsData(filteredSlug);
    }
  };

  // Add active user
  const addUser = async () => {
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    let data = {
      company_id,
      loginEmail,
      code,
      project_id: id,
    };
    await ActiveUsersService.AddUser(data)
      .then((res) => {})
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setTimeout(() => {
          getActiveUsers(data);
        }, 500);
      });
  };
  const removeUser = () => {
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    let data = {
      company_id,
      loginEmail,
      code,
      project_id: id,
    };
    ActiveUsersService.RemoveUser(data)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const getActiveUsers = (data) => {
    ActiveUsersService.getUsers(data)
      .then((res) => {
        // console.log(res, "ActiveUsers");
        setActiveUsers(res);
        setActiveUsersIcon(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [ActiveUsers, setActiveUsers] = useState([]);
  const [ActiveUsersIcon, setActiveUsersIcon] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      addUser();
    }, 200);
    return () => {
      removeUser();
    };
  }, []);

  // remove user if no move

  const [initialized, setInitialized] = useState(false);
  const inactivityTimeoutRef = useRef(null);
  useEffect(() => {
    const resetInactivityTimeout = () => {
      clearTimeout(inactivityTimeoutRef.current);

      inactivityTimeoutRef.current = setTimeout(
        () => {
          let code = localStorage.getItem("token");
          let loginEmail = localStorage.getItem("email");
          let data = {
            company_id,
            loginEmail,
            code,
            project_id: id,
          };
          ActiveUsersService.RemoveUser(data)
            .then((res) => {
              // console.log(res, "user removed");
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              setTimeout(() => {
                getActiveUsers(data);
              }, 200);
            });

          // console.log("No movement for two hours or tab/browser closed");
        },
        //  1 * 60 * 100);
        2 * 60 * 60 * 1000
      ); // 2 hours in milliseconds
    };

    const handleUserActivity = () => {
      resetInactivityTimeout();
    };

    const handleTabClose = () => {
      let code = localStorage.getItem("token");
      let loginEmail = localStorage.getItem("email");
      let data = {
        company_id,
        loginEmail,
        code,
        project_id: id,
      };
      ActiveUsersService.RemoveUser(data)
        .then((res) => {
          // console.log(res, "user removed");
        })
        .catch((err) => {
          console.log(err);
        });

      // console.log("Tab closed");
    };

    document.addEventListener("mousemove", handleUserActivity);
    document.addEventListener("keydown", handleUserActivity);
    window.addEventListener("beforeunload", handleTabClose);

    resetInactivityTimeout();
    setInitialized(true);
    return () => {
      clearTimeout(inactivityTimeoutRef.current);
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, [initialized]);

  const { EditedRows, newRows, clearNewRows, deletedRows } = useSelector(
    (state) => state.projectDetails
  );

  // save project row (Edit or Add)

  const hanldeSave = () => {
    // setisLoading(true);
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    let editRequests = [];
    let addRequests = [];
    let deleteRequests = [];

    let addRowsData = [];
    let editRowsData = [];

    [...EditedRows, ...newRows].map((row) => {
      let newRow = {
        // company_id: company_id,
        // project_id: id,
        // code,
        // loginEmail,
        entryID: row.id,
        specSection: row["specSection"],
        specNumber: row["specNumber"],
        specTitle: row["specTitle"],
        specRevision: row["specRevision"],
        specType: row["specType"],
        specResponse: row["specResponse"],
        responsibleContractor: row["responsibleContractor"],
        issueContract: row["issueContract"],
        contractIssuedRelease: row["contractIssuedRelease"],
        submittalPrepTime: String(row["submittalPrepTime"]),
        submittalRequired: row["submittalRequired"],
        submittalIssued: row["submittalIssued"],
        approvalTime: row["approvalTime"],
        submittalApprovalDue: row["submittalApprovalDue"],
        submittalReturned: row["submittalReturned"],
        leadTime: String(row["leadTime"]),
        scheduleID: row["scheduleID"] || "",
        dateRequiredOnSite: row["dateRequiredOnSite"],
        subCommittedDeliveryDate: row["subCommittedDeliveryDate"],
        projectedDeliveryDate: row["projectedDeliveryDate"],
        ActualDeliveryDate: row["actualDeliveryDate"],
        custom1: row["custom1"],
        custom2: row["custom2"],
        custom3: row["custom3"],
        custom4: row["custom4"],
        custom5: row["custom5"],
        custom6: row["custom6"],
        custom7: row["custom7"],
        custom8: row["custom8"],
        custom9: row["custom9"],
        custom10: row["custom10"],
        custom11: row["custom11"],
        custom12: row["custom12"],
        custom13: row["custom13"],
        custom14: row["custom14"],
        custom15: row["custom15"],
        custom16: row["custom16"],
        custom17: row["custom17"],
        custom18: row["custom18"],
        custom19: row["custom19"],
        custom20: row["custom20"],
        custom21: row["custom21"],
        custom22: row["custom22"],
        custom23: row["custom23"],
        custom24: row["custom24"],
        custom25: row["custom25"],
        entryOrigin: row["entryOrigin"],
      };
      if (row["isNew"] && row["isDeleted"] == false) {
        // CHECKS IF ROW IS NEW
        addRowsData.push(newRow);
      } else if (!row["isNew"] && !row["isDeleted"] && row["isEdited"]) {
        // CHECKS IF ROW IS EDITED
        editRowsData.push(newRow);
      }
    });

    let authData = {
      company_id: company_id,
      project_id: id,
      code,
      loginEmail,
    };

    if (addRowsData.length > 0) {
      let newRowsPromiseToDo = ProjectServices.addRow(addRowsData, authData);
      addRequests.push(newRowsPromiseToDo);
    }

    if (editRowsData.length > 0) {
      let editRowsPromiseToDo = ProjectServices.EditRow(editRowsData, authData);
      editRequests.push(editRowsPromiseToDo);
    }

    if (deletedRows.length > 0) {
      // Filter out items with an empty entryID  -- New Items
      let deletedRowsData = {
        entryID: deletedRows
          .filter((item) => item.values.entryID !== "")
          .map((item) => item.values.entryID),
      };

      // Proceed only if there are valid entryIDs to delete (ignore deleted new items)
      if (deletedRowsData.entryID.length > 0) {
        let deletedRowsPromiseToDo = ProjectServices.deleteRow(
          deletedRowsData,
          authData
        );
        deleteRequests.push(deletedRowsPromiseToDo);
      }
    }

    Promise.all([...addRequests, ...editRequests, ...deleteRequests])
      .then((res) => {
        // console.log("res", res);
        setTimeout(() => {
          if (projectsAccessLevelValue < 2) {
            setCheckedRows([]);
          }
          dispatch(clearAllRows());
          dispatch(reloadProjects());
        }, 200);
      })
      .catch((err) => {
        setTimeout(() => {
          toast.error("Something went wrong, try again later.");
        }, 500);
      })
      .finally(() => {
        dispatch(setUnsavedChangesModal(false));
        dispatch(setUnsavedChanges(false));
        if (unsavedChangesModal) {
          removeUser();
          navigate(unsavedChangesModal);
        }
      });
  };

  const [submittalLoader, setsubmittalLoader] = useState(false);

  // Load data from Submittals When first time enter
  const GetFromSubmittals = () => {
    setsubmittalLoader(true);
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = { company: company_id, project: id, code, loginEmail: email };
    ProjectServices.getProjectsDetailsFromSubmittals(ReqObj)
      .then((res) => {
        dispatch(saveProjectDetails(res));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setTimeout(() => {
          setsubmittalLoader(false);
        }, 200);
      });
  };

  const { Panel } = Collapse;

  const Extra = ({ sumValue, values }) =>
    values ? (
      <div className="d-flex align-items-center justify-content-end gap-2">
        <GrowingAlert number={values["draft"]} type="draft" page="dashboard" />
        <GrowingAlert
          number={values["approaching"]}
          type="pending"
          page="dashboard"
        />
        <GrowingAlert
          number={values["overdue"]}
          type="overdue"
          page="dashboard"
        />
        <GrowingAlert
          number={values["completed"]}
          type="completed"
          page="dashboard"
        />
        <Text className={`${styles.trackingMat}`}>
          {sumValue} Tracking Materials
        </Text>
      </div>
    ) : null;

  // Handle subdivision's Collapses
  const [collapsed, setcollapsed] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const [showBulkForm, setShowBulkForm] = useState(false);
  const handleCollapse = (name) => {
    // console.log(name, "name");

    // if (collapsed.includes(name)) {
    //   setcollapsed(collapsed.filter((item) => item !== name));
    // } else {
    //   setcollapsed([...collapsed, name]);
    // }
    collapsed[0] == name ? setcollapsed([]) : setcollapsed([name]);
  };

  // Remove active user if no movement in the screen

  const [searchValue, setsearchValue] = useState("");
  const [filteredData, setfilteredData] = useState([]);

  const handleSearchResults = () => {
    let titleKeys =
      projectDetails?.content && Object.keys(projectDetails?.content);
    let searchResults =
      projectDetails?.content &&
      titleKeys?.filter(
        (item) =>
          item.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
          Object.keys(projectDetails?.content[item])
            ?.join("")
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
      );
    if (searchResults?.length) {
      setfilteredData(searchResults);
    } else {
      setfilteredData([]);
    }
  };
  useEffect(() => {
    handleSearchResults();
  }, [searchValue, projectDetails?.content]);
  const valuesArray = Object.keys(projectDetails?.divisionsProgress ?? {}).map(
    (progress) => projectDetails?.divisionsProgress?.[progress]
  );

  useEffect(() => {
    if (EditedRows.length > 0 || newRows.length > 0) {
      dispatch(setUnsavedChanges(true));
    } else {
      dispatch(setUnsavedChanges(false));
    }
  }, [EditedRows.length, newRows.length]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (unsavedChanges) {
        const confirmationMessage = "";
        e.preventDefault();
        e.returnValue = confirmationMessage; // For older browsers
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [unsavedChanges]);

  const isApplyChanges = useMemo(() => {
    return (
      projectDetails.usersInfo?.[0].accessLevel == 1 &&
      (projectDetails.usersInfo?.[0]["leadTimeGranularPermission"] == "3" ||
        projectDetails.usersInfo?.[0][
          "subCommittedDeliveryDateGranularPermission"
        ] == "3")
    );
  }, [projectDetails]);

  // handle select view

  let SelectViewOptions = projectDetails?.viewsInfo?.viewNames?.map((item) => ({
    label: item,
    value: item,
  }));

  const updateView = (value) => {
    setSelectedView(value);

    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let ReqObj = {
      company: company_id,
      projectID: id,
      code,
      loginEmail: email,
      viewName: value,
    };
    ProjectServices.setLastUsedView(ReqObj)
      .then((res) => {
        dispatch(reloadProjects());
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  const [totalTrackingMaterials, setTotalTrackingMaterials] = useState(0);

  useEffect(() => {
    if (
      projectDetails &&
      Object.keys(projectDetails).length &&
      filteredData?.length
    ) {
      const totalSum = filteredData.reduce((acc, div) => {
        const dataArray = Object.values(projectDetails?.content?.[div] || {});
        let sum = 0;
        for (const array of dataArray) {
          sum += array?.length || 0;
        }
        return acc + sum;
      }, 0);

      setTotalTrackingMaterials(totalSum);
    }
  }, [projectDetails, filteredData]);

  return (
    <>
      {!isLoading &&
      projectsAccessLevelValue !== undefined &&
      projectsAccessLevelValue < 1 ? (
        <div className="text-center mt-5">
          <Text>
            You do not have the necessary permissions to access this page.
          </Text>
        </div>
      ) : projectsAccessLevelValue === 1 &&
        projectDetails?.usersInfo?.[0]
          ?.viewOnlyAssignedEntriesGranularPermission === "3" &&
        projectDetails?.content &&
        Object.keys(projectDetails.content).length === 0 &&
        !isLoading ? (
        <div className="text-center mt-5">
          <Text>You do not have any tasks assigned to you at the moment.</Text>
        </div>
      ) : (
        <>
          <ProjectHeader
            ActiveUsers={ActiveUsers}
            ActiveUsersIcon={ActiveUsersIcon}
          />
          <Affix offsetTop={0}>
            <Container>
              <ContentContainer className="mt-3 shadow-sm border-1 border-info">
                <div className="d-flex justify-content-between align-items-center">
                  {/* search bar */}

                  <div className="d-flex align-items-center gap-2 p-3">
                    <Settings
                      projectsAccessLevelValue={projectsAccessLevelValue}
                    />

                    <InputComponent
                      className={`mb-0 ${styles.search} w-100`}
                      placeholder="Search"
                      Icon={<img src={SearchIcon} alt="search icon" />}
                      onChange={(e) => setsearchValue(e)}
                      value={searchValue}
                    />

                    <div>
                      <Select
                        showSearch
                        placeholder="Select a View"
                        className="w-100"
                        disabled={isLoading || projectsAccessLevelValue < 2}
                        style={{
                          minWidth: "200px",
                        }}
                        onChange={updateView}
                        value={selectedView}
                        options={SelectViewOptions}
                      />
                    </div>
                  </div>
                  {/* get from sub */}
                  {Object.keys(projectDetails).length === 0 && !isLoading && (
                    <div className={`d-flex align-items-center px-3 `}>
                      <ButtonComponent
                        className={styles.smallButton}
                        onClickAction={
                          projectsAccessLevelValue < 2
                            ? null
                            : GetFromSubmittals
                        }
                        title={"Get From Submittals"}
                        disabled={
                          projectsAccessLevelValue < 2 || submittalLoader
                            ? true
                            : false
                        }
                      />
                    </div>
                  )}
                  {/* filters component goes here should add optional filters */}
                  <div className="d-flex">
                    <FilterSection
                      isLoading={isLoading}
                      filterFunction={handleFilter}
                      removeUser={() => removeUser()}
                      projectsAccessLevelValue={projectsAccessLevelValue}
                      filteredData={filteredData}
                      checkedRows={checkedRows}
                      setCheckedRows={setCheckedRows}
                      showBulkForm={showBulkForm}
                      setShowBulkForm={setShowBulkForm}
                    />

                    {isApplyChanges ? (
                      <div className={`d-flex align-items-center ps-3 `}>
                        {
                          <ButtonComponent
                            className={styles.smallButton1}
                            onClickAction={() => hanldeSave()}
                            title={"Submit For Approval"}
                            disabled={
                              (EditedRows.length === 0 &&
                                newRows.length === 0 &&
                                deletedRows.length === 0) ||
                              isLoading
                            }
                          />
                        }
                      </div>
                    ) : (
                      <div className={`d-flex align-items-center ps-3 `}>
                        {
                          <ButtonComponent
                            // className={styles.smallButton}
                            className=" me-3 px-3"
                            onClickAction={() => hanldeSave()}
                            title={"Save Changes"}
                            disabled={
                              (EditedRows.length === 0 &&
                                newRows.length === 0 &&
                                deletedRows.length === 0) ||
                              isLoading
                            }
                          />
                        }
                      </div>
                    )}
                  </div>
                </div>
              </ContentContainer>
            </Container>
          </Affix>
          <Modal
            open={unsavedChangesModal}
            okText={"Save Changes"}
            onOk={hanldeSave}
            cancelText={"Discard"}
            onCancel={() => {
              dispatch(clearRowsData());
              dispatch(setUnsavedChangesModal(false));
              dispatch(setUnsavedChanges(false));
              navigate(unsavedChangesModal);
            }}
            closable={false}
            centered
          >
            <div className="py-4">
              <Text>You have unsaved Changes!</Text>
            </div>
          </Modal>

          {(isLoading || submittalLoader) && <LoadingComponent />}
          {/*  */}
          {!(isLoading || submittalLoader) && (
            <Container className="mt-3">
              {showBulkForm && (
                <BulkAssignEdit
                  projectDetails={projectDetails}
                  projectsAccessLevelValue={projectsAccessLevelValue}
                  checkedRows={checkedRows}
                  setCheckedRows={setCheckedRows}
                  showBulkForm={showBulkForm}
                  setShowBulkForm={setShowBulkForm}
                />
              )}

              {/* TOTAL TRACKING MATERIAL  */}
              <div className=" d-flex justify-content-end mb-3 me-1">
                <div className=" ">
                  <small
                    style={{
                      color: "gray",
                      // backgroundColor: "#f0f0f0",
                      padding: "0.2rem 0.5rem",
                      borderRadius: "5px",
                    }}
                  >
                    {totalTrackingMaterials} Total Tracking Materials
                  </small>
                  {/* <small></small> */}
                </div>
              </div>
              <ContentContainer>
                <Collapse onChange={() => setcollapsed([])}>
                  {Object.keys(projectDetails).length &&
                    filteredData?.map((div, index) => {
                      // Tracking Martials Number
                      const dataArray = Object.values(
                        projectDetails?.content?.[div]
                      );
                      let sum = 0;
                      for (const array of dataArray) {
                        sum += array.length;
                      }
                      const currentValuesArray = valuesArray[index];

                      return (
                        <Panel
                          header={div}
                          className={`bg-white ${styles.collapse}`}
                          extra={
                            <Extra sumValue={sum} values={currentValuesArray} />
                          }
                          key={div}
                        >
                          <ProjectDivision
                            key={
                              "division" +
                              index +
                              JSON.stringify(projectDetails?.content?.[div])
                            }
                            setcollapsed={setcollapsed}
                            handleCollapse={(x) => handleCollapse(x)}
                            collapsed={collapsed}
                            name={div}
                            projectSubDivision={projectDetails?.content?.[div]}
                            checkedRows={checkedRows}
                            setCheckedRows={setCheckedRows}
                          />
                        </Panel>
                      );
                    })}
                </Collapse>
              </ContentContainer>
            </Container>
          )}
          {/*  */}
        </>
      )}
    </>
  );
};

const FilterSection = ({
  filterFunction,
  isLoading,
  removeUser,
  projectsAccessLevelValue,
  filteredData,
  checkedRows,
  setCheckedRows,
  showBulkForm,
  setShowBulkForm,
}) => {
  const { id } = useParams();

  const { projectDetails, unsavedChanges } = useSelector(
    (state) => state.projectDetails
  );

  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [isPersonsModalVisible, setIsPersonsModalVisible] = useState(false);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const dispatch = useDispatch();
  // Filter Visibility
  const handleFilterModalVisibility = () => {
    setIsFilterModalVisible((prev) => !prev);
  };

  // Persons Visibility
  // const { company_id } = useParams();
  // const { id } = useParams();

  const handlePersonsModalVisibility = () => {
    setIsPersonsModalVisible((prev) => !prev);
  };
  // Date Visibility
  const handleDatePickerVisibility = () => {
    setIsDatePickerVisible((prev) => !prev);
  };

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const handleCSVUpload = (file) => {
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let company_id = localStorage.getItem("company_id");

    const reader = new FileReader();

    reader.onload = () => {
      const fileData = reader.result;

      const formData = new FormData();
      formData.append("f_schedule", fileData);

      const data = {
        company_id: company_id,
        project_id: id,
        loginEmail: email,
        code: code,
        f_schedule: fileData,
      };

      ProjectServices.saveUploadedSchedule(data, formData)
        .then((res) => {
          // console.log(res);
          setShowError(false);
          removeUser();
          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .catch((err) => {
          setShowError(true);
          setErrorMessage(err);
        });
    };

    reader.onerror = () => {
      console.error("Failed to read the file.");
    };

    reader.readAsText(file);
  };

  const handleProcurementLogUpload = (file) => {
    // console.log("Uploaded file:", file);

    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    let company_id = localStorage.getItem("company_id");

    const reader = new FileReader();

    reader.onload = () => {
      const fileData = reader.result;

      const formData = new FormData();
      formData.append("f_schedule", fileData);

      const data = {
        loginEmail: email,
        code: code,
        f_procurementLog: fileData,
      };

      ProjectServices.saveProcurementLogSchedule(data, company_id, id)
        .then((res) => {
          // console.log(res);
          setShowError(false);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .catch((err) => {
          console.error(err);
          setShowError(true);
          setErrorMessage(err);
        });
    };

    reader.onerror = () => {
      console.error("Failed to read the file.");
    };

    reader.readAsText(file);
  };

  const ACTIONS = {
    handleFilterModalVisibility,
    handlePersonsModalVisibility,
    handleDatePickerVisibility,
    handleCSVUpload,
    handleProcurementLogUpload,
  };

  const MODALSTATES = {
    date: {
      isVisible: isDatePickerVisible,
      setIsVisible: handleDatePickerVisibility,
    },
    persons: {
      isVisible: isPersonsModalVisible,
      setIsVisible: handlePersonsModalVisibility,
    },
    filter: {
      isVisible: isFilterModalVisible,
      setIsVisible: handleFilterModalVisibility,
      handleFilterFunction: filterFunction,
    },
  };

  const props = {
    accept: ".csv",
    beforeUpload: (file) => {
      handleCSVUpload(file);
      return false; // Prevent default upload behavior
    },
  };

  const propsLog = {
    accept: ".csv",
    beforeUpload: (file) => {
      handleProcurementLogUpload(file);
      return false; // Prevent default upload behavior
    },
  };

  const [isShowScheduleModal, setisShowScheduleModal] = useState(false);
  const [isItemApprovalsModal, setIsItemApprovals] = useState(false);
  const [ItemApprovalsRes, setItemApprovalsRes] = useState([]);
  const [ItemApprovalsHistoryRes, setItemApprovalsHistoryRes] = useState([]);
  const [itemApprovalsResLoader, setItemApprovalsResLoader] = useState(true);

  const showModal = () => {
    setisShowScheduleModal(true);
  };
  const showItemApprovalsModal = () => {
    setIsItemApprovals(true);
    setItemApprovalsResLoader(true);

    let company_id = localStorage.getItem("company_id");
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");
    const data = {
      company_id: company_id,
      project_id: id,
      code: code,
      email: email,
    };
    ProjectServices.getItemsPendingApprovalsHistory(data)
      .then((res) => {
        setItemApprovalsHistoryRes(res);
        // console.log(res, "history approvalsssssssssss");
      })
      .catch((err) => {
        console.error(err);
      });

    ProjectServices.getItemsPendingApprovals(data)
      .then((res) => {
        setItemApprovalsResLoader(false);
        // console.log(res, "approvalsssssssssss");
        setItemApprovalsRes(res);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setItemApprovalsResLoader(false));
  };
  const handleCancel = () => {
    setShowError(false);
    setisShowScheduleModal(false);
  };
  const handleItemApprovalsCancel = () => {
    setItemApprovalsRes([]);
    setIsItemApprovals(false);
  };

  const [isShowProcurementModal, setisProcurementModal] = useState(false);
  const showProcurementModal = () => {
    setisProcurementModal(true);
  };
  const handleCancelProcurement = () => {
    setShowError(false);
    setisProcurementModal(false);
  };

  //Error Component
  const ErrorList = ({ errorMessage }) => {
    // Check if the errors array is empty
    if (errorMessage.length === 0) {
      setErrorMessage(false);
      return null; // Return null if there are no errors
    }

    return (
      <div style={{ maxHeight: "400px", overflowY: "auto" }}>
        <ul>
          {errorMessage.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      </div>
    );
  };

  const AlertMessage = ({ errorMessage }) => {
    // Check if the errors array is empty
    return (
      <div>
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
        >
          <Alert
            message="Error(s)"
            description={<ErrorList errorMessage={errorMessage} />}
            type="error"
          />
        </Space>
      </div>
    );
  };

  const handleNotAvailable = useCallback((value) => {
    return value || "-";
  }, []);

  const [holdingItems, setHoldingItems] = useState([]);
  const [submitPendingLoader, setsubmitPendingLoader] = useState(false);

  const handlePendingApprovalsInput = (notes, item) => {
    // console.log(notes, item, "notes")
    let tempArr = [...holdingItems];

    const pendingApprovalIndex = tempArr.findIndex(
      (element) =>
        item.entryID == element.entryID &&
        item.approvalType == element.approvalType
    );
    tempArr[pendingApprovalIndex].notes = notes;
    // console.log(tempArr[pendingApprovalIndex], "pendingApprovalIndex");
    setHoldingItems(tempArr);
  };

  const handlePendingApprovals = (item, decision) => {
    const Arr = [...holdingItems];
    const obj = {
      projectName: projectDetails.projectInfo,
      entryID: item.entryID,
      decision: decision,
      approvalType: item.approvalType,
      notes: "",
    };
    const alreadyExist = Arr.find((element) => {
      return (
        obj.entryID == element.entryID &&
        obj.approvalType == element.approvalType
      );
    });
    // console.log(alreadyExist, "alreadyExist");
    if (!alreadyExist) {
      Arr.push(obj);
      setHoldingItems(Arr);
    }
  };
  const handleRevertPendingApprovals = (item) => {
    const Arr = [...holdingItems];
    const index = Arr.findIndex(
      (ele) =>
        ele.approvalType == item.approvalType && item.entryID == ele.entryID
    );
    Arr.splice(index, 1);
    setHoldingItems(Arr);
  };
  const handleIsVoted = (item) => {
    const x = holdingItems.find((el) => {
      return item.entryID == el.entryID && item.approvalType == el.approvalType;
    });
    return x?.decision;
    // console.log(x, "xxx");
  };
  const handleAcceptAll = () => {
    const newArr = ItemApprovalsRes.map((item) => {
      return {
        projectName: projectDetails.projectInfo,
        entryID: item.entryID,
        decision: "Approve",
        approvalType: item.approvalType,
        notes: "",
      };
    });

    setHoldingItems(newArr);
  };

  const onsubmitPendingApprovals = () => {
    setsubmitPendingLoader(true);
    let company_id = localStorage.getItem("company_id");
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");

    const data = {
      company_id: company_id,
      project_id: id,
      code: code,
      email: email,
      values: holdingItems,
    };
    ProjectServices.sendItemsPendingApprovals(data)
      .then((res) => {
        // console.log(data, "ItemsPendingApprovals");
        setIsItemApprovals(false);
        setHoldingItems([]);
        toast.success("Your approval decisions are now applied");
        dispatch(reloadProjects());
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong, please try again later!");
      })
      .finally(() => setsubmitPendingLoader(false));
  };

  function HideItems(checkedRows) {
    let company_id = localStorage.getItem("company_id");
    let code = localStorage.getItem("token");
    let email = localStorage.getItem("email");

    const toastId = toast.loading("Hiding submittals in progress");

    const entryIDs = Object.keys(checkedRows).filter(
      (entryID) => checkedRows[entryID]
    );

    let ReqObj = {
      company_id: company_id,
      id: id,
      code,
      loginEmail: email,
      entryID: entryIDs,
    };
    HiddenItemsService.procurementLogHideEntry(ReqObj)
      .then((res) => {
        toast.success("Submittals hidden successfully!", { id: toastId });
        setCheckedRows([]);
        dispatch(reloadProjects());
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          "Failed to hide the selected item(s). Please try again later.",
          { id: toastId }
        );
      });
  }

  function handleMenuClick(e) {
    if (e.key === "2") {
      //Upload Schedule Modal
      showModal();
    }
  }

  const items = [
    {
      label: (
        <ExportTable
          DashboardData={projectDetails}
          projectsAccessLevelValue={projectsAccessLevelValue}
          PageIsLoading={isLoading}
          disabledButton={unsavedChanges}
          filteredData={filteredData}
        />
      ),
      key: "1",
      disabled: unsavedChanges || projectsAccessLevelValue < 2,
    },
    {
      label: "Upload Schedule",
      key: "2",
      icon: <UploadOutlined style={{ fontSize: "16px" }} />,
      disabled: projectsAccessLevelValue < 2,
    },
    {
      label: (
        <Link
          to={`${window.location.pathname}/hidden-items`}
          style={{ textDecoration: "none" }}
        >
          View Hidden Items
        </Link>
      ),
      key: "3",
      icon: <EyeInvisibleOutlined style={{ fontSize: "15px" }} />,
      disabled: projectsAccessLevelValue < 2,
    },
    {
      label: (
        <Link
          to={`${window.location.pathname}/deleted-items`}
          style={{ textDecoration: "none" }}
        >
          View Deleted Items
        </Link>
      ),
      key: "4",
      icon: <HistoryOutlined style={{ fontSize: "15px" }} />,
      disabled: projectsAccessLevelValue < 3,
    },
  ];

  function handleBulkMenuClick(e) {
    if (e.key === "2") {
      //Show Bulk Assign / Edit Form
      setShowBulkForm(true);
    } else if (e.key === "3") {
      //Bulk Hide
      HideItems(checkedRows);
    }
  }

  const bulkitems = [
    {
      label: (
        <PostSubmittals
          projectDetails={projectDetails}
          projectsAccessLevelValue={projectsAccessLevelValue}
          checkedRows={checkedRows}
          setCheckedRows={setCheckedRows}
        />
      ),
      key: "1",
      disabled: unsavedChanges || projectsAccessLevelValue < 2,
    },
    {
      label: "Bulk Assign/Edit",
      icon: <FormOutlined style={{ fontSize: "16px" }} />,
      key: "2",
      disabled: unsavedChanges,
    },
    {
      label: "Bulk Hide",
      icon: <EyeInvisibleOutlined style={{ fontSize: "16px" }} />,
      key: "3",
      disabled: unsavedChanges || projectsAccessLevelValue < 2,
    },
    {
      label: (
        <BulkDelete
          projectDetails={projectDetails}
          projectsAccessLevelValue={projectsAccessLevelValue}
          checkedRows={checkedRows}
          setCheckedRows={setCheckedRows}
        />
      ),
      key: "4",
      disabled: unsavedChanges || projectsAccessLevelValue < 3,
      danger: true,
    },
  ];

  const menuProps = {
    items: items,
    onClick: handleMenuClick,
  };

  const bulkMenuProps = {
    items: bulkitems,
    onClick: handleBulkMenuClick,
  };

  const isAllCheckedRowsFalse = (checkedRows) => {
    for (const key in checkedRows) {
      if (checkedRows.hasOwnProperty(key) && checkedRows[key]) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <ul className="d-flex gap-2 m-0 p-0 list-unstyled w-70 justify-content-end flex-wrap">
        <li className="d-flex align-items-center position-relative">
          {/* <Upload className="d-flex" {...props}>
            <Button icon={<UploadOutlined />}>Upload Schedule</Button>
          </Upload> */}

          <div
            onClick={
              projectsAccessLevelValue < 2 ? null : showItemApprovalsModal
            }
            className={`${styles.letterContainer}`}
          >
            {/* <Text className={`${styles.firstLetters}`}>Upload Schedule</Text> */}
            <Button
              disabled={projectsAccessLevelValue < 2}
              className={
                projectDetails.length == 0 ||
                projectDetails?.itemsPendingApprovals?.length > 0
                  ? styles.notification
                  : styles.ItemsPendingCon
              }
            >
              <Text fontSize={15}>Items Pending Approval</Text>
              {/* {ItemApprovalsRes.length == 0 ? (
                <div className={styles.notificationDot}></div>
              ) : null} */}
            </Button>
          </div>

          <Modal
            className={styles.pendingApprovalModal}
            open={isItemApprovalsModal}
            onCancel={handleItemApprovalsCancel}
            destroyOnClose={true}
            footer={null}
          >
            <Tabs defaultActiveKey="tab1" centered>
              <Tabs.TabPane tab="Items Pending Approval" key="tab1">
                {itemApprovalsResLoader ? (
                  <LoadingComponent />
                ) : ItemApprovalsRes.length == 0 ? (
                  <div style={{ marginBlock: "2vw" }} className="py-4">
                    <Text color="#0196db" className="text-center">
                      No items pending approval
                    </Text>
                  </div>
                ) : (
                  <div>
                    {ItemApprovalsRes.map((item, index) => {
                      let sameBefore =
                        item.entryID ==
                        ItemApprovalsRes[index - 1]?.["entryID"];
                      let hasVoted = handleIsVoted(item);
                      // console.log(hasVoted, index, "hasVoted");
                      return (
                        <>
                          {(!sameBefore || index == 0) && (
                            <div className="mb-2">
                              <Text fontSize={18}>
                                {item.submittalNumber
                                  ? `${item.submittalNumber}`
                                  : null}
                                {item.submittalRevision
                                  ? `.${item.submittalRevision} - `
                                  : null}
                                {`${item.specTitle}`}
                              </Text>
                            </div>
                          )}

                          <div
                            className={
                              !hasVoted
                                ? styles.pendingApprovalItem
                                : hasVoted != "Approve"
                                ? styles.pendingApprovalItemChekedRejected
                                : (hasVoted = "Reject"
                                    ? styles.pendingApprovalItemChekedApproved
                                    : null)
                            }
                            // style={{ border: hasVoted ? "red" : "blue" }}
                          >
                            <div className={styles.pendingApprovalBoxTxt}>
                              <Text
                                // fontFamily="bold"
                                fontSize={14}
                                style={{ width: "100%" }}
                                dontWrap={false}
                              >
                                {`${item.firstName} ${item.lastName} ${
                                  item.values.oldValue &&
                                  item.values.newValue === ""
                                    ? "is requesting to delete"
                                    : item.values.oldValue &&
                                      item.values.newValue
                                    ? "is requesting to change"
                                    : "is requesting to set"
                                } the value of ${
                                  item.approvalType === "leadTime"
                                    ? "Lead Time" +
                                      (item.values.newValue === "" ? "." : "")
                                    : "Sub Committed Delivery Date" +
                                      (item.values.newValue === "" ? "." : "")
                                } ${
                                  item.values.oldValue
                                    ? item.values.newValue === ""
                                      ? `It was previously set to ${handleNotAvailable(
                                          item.values.oldValue
                                        )}`
                                      : `from ${handleNotAvailable(
                                          item.values.oldValue
                                        )}`
                                    : ""
                                } 
                                ${
                                  item.approvalType === "leadTime" &&
                                  item.oldValue !== ""
                                    ? "week(s)"
                                    : ""
                                }
                                ${
                                  item.values.newValue
                                    ? `to ${handleNotAvailable(
                                        item.values.newValue
                                      )}`
                                    : ""
                                }
                                ${
                                  item.approvalType === "leadTime" &&
                                  item.values.newValue !== ""
                                    ? "week(s)"
                                    : ""
                                }`}
                              </Text>
                              <span className={styles.timestampSpan}>
                                {item.timestamp}
                              </span>
                            </div>
                            {!hasVoted ? (
                              <div className={styles.btnsCon}>
                                <Button
                                  type="default"
                                  style={{
                                    // backgroundColor: "#67a868",
                                    color: "#458546",
                                    ":hover": {
                                      color: "black",
                                      backgroundColor: "lightgreen",
                                    },
                                  }}
                                  onClick={() =>
                                    handlePendingApprovals(item, "Approve")
                                  }
                                >
                                  Approve
                                </Button>
                                <Button
                                  danger
                                  type="text"
                                  style={{
                                    // backgroundColor: "transparent",
                                    // border: "none",
                                    color: "red",
                                  }}
                                  onClick={() =>
                                    handlePendingApprovals(item, "Reject")
                                  }
                                >
                                  Reject
                                </Button>
                              </div>
                            ) : (
                              <div className={styles.btnsCon}>
                                <Button
                                  danger
                                  type="text"
                                  style={{
                                    // backgroundColor: "transparent",
                                    // border: "none",
                                    // padding: "0px",
                                    color: "red",
                                  }}
                                  onClick={() =>
                                    handleRevertPendingApprovals(item)
                                  }
                                >
                                  Revert
                                </Button>
                              </div>
                            )}
                            {hasVoted ? (
                              <div className={styles.pendingApprovalInput}>
                                <InputComponent
                                  maxLength={140}
                                  placeholder="Notes"
                                  className={styles.pendingNotes}
                                  onBlur={(e) =>
                                    handlePendingApprovalsInput(
                                      e.target.value,
                                      item
                                    )
                                  }
                                />
                              </div>
                            ) : null}
                          </div>
                        </>
                      );
                    })}

                    <div className={styles.acceptAllBtn}>
                      <Button
                        type="primary"
                        // style={{
                        //   color: "white",
                        //   border: "none",
                        //   backgroundColor: "#004c92",
                        // }}
                        onClick={() => onsubmitPendingApprovals()}
                      >
                        Submit
                      </Button>
                      <Button
                        type="default"
                        // style={{
                        //   color: "#004c92",
                        //   border: "1px solid #004c92",
                        //   backgroundColor: "transparent",
                        // }}
                        onClick={() => handleAcceptAll()}
                      >
                        Approve All
                      </Button>
                    </div>
                  </div>
                )}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Change History" key="tab2">
                {itemApprovalsResLoader ? (
                  <LoadingComponent />
                ) : (
                  <>
                    {/* <Text color="#0196db" className="text-center">
                      Change History
                    </Text> */}
                    <div className={styles.itemsPendingApprovalhistory}>
                      {ItemApprovalsHistoryRes.map((item, index) => {
                        let sameBefore =
                          item.entryID ==
                          ItemApprovalsHistoryRes[index - 1]?.["entryID"];
                        return (
                          <>
                            {(!sameBefore || index == 0) && (
                              <div className="mb-2">
                                <Text dontWrap={false} fontSize={16}>
                                  {item.submittalNumber
                                    ? `${item.submittalNumber}`
                                    : null}
                                  {item.submittalRevision
                                    ? `.${item.submittalRevision} - `
                                    : null}
                                  {`${item.specTitle}`}
                                </Text>
                              </div>
                            )}
                            <div className={styles.pendinghistory}>
                              <Text dontWrap={false} fontSize={14}>
                                {`${item.managerFirstName} ${
                                  item.managerLastName
                                } has ${
                                  item.decision === "Approve"
                                    ? "approved"
                                    : "rejected"
                                } ${item.subContractorFirstName} ${
                                  item.subContractorLastName
                                }'s ${
                                  item.oldValue && item.newValue === ""
                                    ? "request to delete the"
                                    : item.oldValue && item.newValue
                                    ? "request to change the"
                                    : "request to set the"
                                } ${
                                  item.approvalType === "leadTime"
                                    ? "Lead Time" +
                                      (item.newValue === "" ? "." : "")
                                    : "Sub Committed Delivery Date" +
                                      (item.oldValue.length > 1 ? "." : "")
                                } ${
                                  item.newValue === ""
                                    ? `It was previously set to `
                                    : item.oldValue
                                    ? "from"
                                    : ""
                                }                              
                                ${item.oldValue}
                                ${
                                  item.approvalType === "leadTime" &&
                                  item.oldValue !== ""
                                    ? "week(s)"
                                    : ""
                                }
                                ${item.newValue ? "to" : ""} ${item.newValue}
                                ${
                                  item.approvalType === "leadTime" &&
                                  item.newValue !== ""
                                    ? "week(s)"
                                    : ""
                                }
                                `}
                              </Text>
                              <div className={styles.dateAndNotes}>
                                {item.notes ? (
                                  <div className="mt-1">
                                    <Text fontSize={14}>
                                      Notes: {item.notes}{" "}
                                    </Text>
                                  </div>
                                ) : null}
                                <div className={styles.timestamp}>
                                  <Text fontSize={14}>{item.timestamp}</Text>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </>
                )}
              </Tabs.TabPane>
            </Tabs>
          </Modal>
        </li>
        {/* {Object.keys(projectDetails).length > 0 && (
          <li className="d-flex align-items-center gap-2 p-1 position-relative">
            <ExportTable
              DashboardData={projectDetails}
              projectsAccessLevelValue={projectsAccessLevelValue}
              PageIsLoading={isLoading}
              disabledButton={unsavedChanges}
              filteredData={filteredData}
            />
          </li>
        )} */}
        <li className="d-flex align-items-center position-relative">
          {/* <Upload className="d-flex" {...props}>
            <Button icon={<UploadOutlined />}>Upload Schedule</Button>
          </Upload> */}
          {/* 
          <div
            onClick={projectsAccessLevelValue < 2 ? null : showModal}
            className={`${styles.letterContainer}`}
          >
            <Button
              disabled={projectsAccessLevelValue < 2}
              icon={<UploadOutlined />}
            >
              Upload Schedule
            </Button>
          </div> */}

          <Modal
            open={isShowScheduleModal}
            onCancel={handleCancel}
            footer={null}
            // centered={true}
          >
            <div className="my-4">
              <Dragger {...props} className="m-b-auto">
                <p className="ant-upload-text my-4">
                  Click or drag file to this area to upload
                </p>
              </Dragger>
              <div className="my-2">
                <Text
                  color="red"
                  dontWrap={false}
                  fontSize={12}
                  className="text-center"
                >
                  * Uploading a new schedule will overwrite any previously
                  uploaded schedules
                </Text>
              </div>

              {showError && <AlertMessage errorMessage={errorMessage} />}

              <div className="mt-4 text-center">
                <p>
                  <a
                    href="/koncurentSampleSchedule.csv"
                    download="koncurentSampleSchedule.csv"
                  >
                    Download Template
                  </a>
                </p>
              </div>
            </div>
          </Modal>
        </li>
        {Object.keys(projectDetails).length === 0 && !isLoading && (
          <li className="d-flex align-items-center gap-2 p-1 position-relative">
            <div
              onClick={
                projectsAccessLevelValue < 2 ? null : showProcurementModal
              }
              className="d-flex"
              {...propsLog}
            >
              <Button
                disabled={projectsAccessLevelValue < 2}
                icon={<UploadOutlined />}
              >
                Upload Procurement Log
              </Button>
            </div>

            <Modal
              // title="Basic Modal"
              open={isShowProcurementModal}
              onCancel={handleCancelProcurement}
              footer={null}
              // centered={true}
            >
              <div className="my-4">
                <Dragger {...propsLog}>
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  {/* <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from
                uploading company data or other band files
              </p> */}
                </Dragger>

                {showError && <AlertMessage errorMessage={errorMessage} />}
                <div className="mt-4 text-center">
                  <p>
                    <a
                      href="/koncurentSampleProcurementLog_template.csv"
                      download="koncurentSampleProcurementLog_template.csv"
                    >
                      Download Template
                    </a>
                  </p>
                </div>
              </div>
            </Modal>
          </li>
        )}

        <div className=" align-self-center d-flex gap-3">
          <Dropdown
            menu={bulkMenuProps}
            placement="bottomLeft"
            disabled={
              isLoading ||
              unsavedChanges ||
              isAllCheckedRowsFalse(checkedRows) < 1
            }
          >
            {unsavedChanges ? (
              <Tooltip
                title="Save changes first"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button className={`${styles.antBtn}`} disabled>
                  Bulk Actions
                  <DownOutlined
                    style={{ fontSize: "14px", alignSelf: "center" }}
                  />
                </Button>
              </Tooltip>
            ) : isAllCheckedRowsFalse(checkedRows) < 1 ? (
              <Tooltip title="Please select rows first">
                <Button className=" d-inline-flex align-items-center" disabled>
                  Bulk Actions
                  <DownOutlined />
                </Button>
              </Tooltip>
            ) : (
              <Button className={`${styles.antBtn}`}>
                Bulk Actions
                <DownOutlined style={{ fontSize: "14px" }} />
              </Button>
            )}
          </Dropdown>
          <Dropdown
            menu={menuProps}
            placement="bottomLeft"
            disabled={isLoading || projectsAccessLevelValue < 2}
          >
            <Button className={`${styles.antBtn}`}>
              Actions
              <DownOutlined style={{ fontSize: "14px" }} />
            </Button>
          </Dropdown>
        </div>

        {FILTERDATA.map((ele, index) => (
          <li
            className="d-flex align-items-center gap-2 p-1 position-relative"
            key={index}
          >
            <img
              src={ele.image.src}
              alt={ele.image.alt}
              style={{ width: "20px", maxHeight: "20px" }}
            />
            <span
              className={`${styles.filterSectionText}`}
              // style={{ cursor: index == 0 ? "pointer" : "default" }}
              onClick={ACTIONS[ele?.onClickAction]}
            >
              {ele.text}
            </span>
          </li>
        ))}
      </ul>
    </>
  );
};

export default ProjectDetails;
